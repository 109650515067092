import { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Auth/Login";
import Forget from "./Components/Auth/Forget";
import Register from "./Components/Auth/Register";
import Home from "./Components/Dashboard/Home";
import UpdateCourse from "./Components/Dashboard/UpdateCourse";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const AdminInfo = sessionStorage.getItem("AdminInfo");
    if (AdminInfo) {
      const parsedUserInfo = JSON.parse(AdminInfo);
      if (parsedUserInfo.token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <>
      <div className="App">
        <Router>
          <Routes>
            {/* {isAuthenticated ? (
              <> */}
            <Route path="/" element={<Home />} />
            <Route path="/update-course/:slug" element={<UpdateCourse />} />
            <Route path="*" element={<Navigate to="/" />} />
            {/* </>
            ) : (
              <> */}
            <Route path="/login" element={<Login />} />
            <Route path="/forget" element={<Forget />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" />} />
            {/* </>
            )} */}
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
