import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";

const ManageCourse = () => {
  const [Courses, setCourses] = useState([]);
  const toast = useToast();
  const storedData = JSON.parse(sessionStorage.getItem("AdminInfo"));
  const token = storedData.token;
  const navigate = useNavigate();

  const fetchCourses = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${mod.api_url}/api/course/get_All_course`,
        config
      );
      console.log("Fetched courses:", data); // Inspect the data
      setCourses(data?.Courses);
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleUpdate = (slug) => {
    console.log("Course ID:", slug);
    navigate(`/update-course/${slug}`);
  };

  const handleDelete = async (_id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this course?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };
              const response = await axios.delete(
                `${mod.api_url}/api/course/course_delete/${_id}`,
                config
              );
              console.log("Delete response:", response);
              setCourses(Courses.filter((course) => course._id !== _id));
              toast({
                title: "Course deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            } catch (error) {
              console.error("Error deleting course:", error.response || error);
              toast({
                title: "Error deleting course.",
                description:
                  error.response?.data?.message || "Something went wrong!",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Container maxW="container.xl" p={4}>
      <Text fontSize="2xl" mb={4}>
        Manage All Courses
      </Text>
      <Divider mb={4} />
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={6}
      >
        {Courses &&
          Courses.map((course, index) => (
            <GridItem key={index} w="100%">
              <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                <Image
                  src={course.image || "placeholder.jpg"}
                  alt={course.name}
                  width="100%"
                  height="300px"
                />
                <VStack p={4} align="stretch">
                  <Text fontSize="xl" fontWeight="bold" fontFamily="sans-serif">
                    {course.name}
                  </Text>
                  <Text>
                    Price: {course.price} {course.currency}
                  </Text>
                  <Flex justifyContent="space-between">
                    <Button
                      colorScheme="teal"
                      width="48%"
                      onClick={() => handleUpdate(course.slug)}
                    >
                      Update
                    </Button>
                    <Button
                      colorScheme="red"
                      width="48%"
                      onClick={() => handleDelete(course._id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            </GridItem>
          ))}
      </Grid>
    </Container>
  );
};

export default ManageCourse;
