import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  InputGroup,
  InputRightElement,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";

const CreateCourse = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [offers, setOffers] = useState("");
  const [currency, setCurrency] = useState("INR");
  const [pdf, setPdf] = useState([]); // Store multiple PDFs
  const [videourl, setVideourl] = useState("");
  const [image, setImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [offerDate, setOfferDate] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    const storedData = JSON.parse(sessionStorage.getItem("AdminInfo"));
    const token = storedData.token;

    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("offers", offers);
    formData.append("currency", currency);
    formData.append("videourl", videourl);

    // Append the image file if selected
    if (image) {
      formData.append("image", image);
    }

    // Append each selected PDF file (handling multiple files)
    if (pdf.length > 0) {
      Array.from(pdf).forEach((file) => {
        formData.append("pdf", file); // Handle multiple files under "pdf"
      });
    }

    formData.append("rating", rating);
    formData.append("offerDate", offerDate);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${mod.api_url}/api/course/create_course`,
        formData,
        config
      );

      toast({
        title: "Course created successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset form
      setName("");
      setDescription("");
      setPrice("");
      setOffers("");
      setVideourl("");
      setCurrency("INR");
      setPdf([]); // Clear selected PDFs after submission
      setImage(null);
      setRating(0);
      setOfferDate("");
    } catch (error) {
      toast({
        title: "Error occurred.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxW="container.md" p={4}>
      <Text fontSize="2xl" mb={4}>
        Create Your Courses
      </Text>
      <VStack spacing={4} align="stretch">
        <FormControl id="name" isRequired>
          <FormLabel>Course Name</FormLabel>
          <Input
            placeholder="Enter course name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl id="description" isRequired>
          <FormLabel>Course Description</FormLabel>
          <Textarea
            placeholder="Enter course description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <FormControl id="price" isRequired>
          <FormLabel>Course Price</FormLabel>
          <InputGroup>
            <Input
              placeholder="Enter course price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              type="number"
            />
            <InputRightElement>
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="INR">INR</option>
              </Select>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="offers">
          <FormLabel>Offers</FormLabel>
          <Input
            placeholder="Enter any offers"
            value={offers}
            onChange={(e) => setOffers(e.target.value)}
          />
        </FormControl>
        <FormControl id="pdf">
          <FormLabel>PDF Files</FormLabel>
          <Input
            type="file"
            accept="application/pdf"
            onChange={(e) => setPdf(e.target.files)} // Handle multiple PDFs
            style={{ padding: "3px" }}
            multiple
          />
        </FormControl>
        <FormControl id="videourl">
          <FormLabel>Video URL </FormLabel>
          <Input
            placeholder="Enter course Video URL"
            value={videourl}
            onChange={(e) => setVideourl(e.target.value)}
          />
        </FormControl>
        <FormControl id="image">
          <FormLabel>Course Image</FormLabel>
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])} // Only one image file
            style={{ padding: "3px" }}
          />
        </FormControl>
        <FormControl id="rating" isRequired>
          <FormLabel>Rating</FormLabel>
          <NumberInput
            max={5}
            min={0}
            step={0.5}
            value={rating}
            onChange={(valueString) => setRating(parseFloat(valueString))}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl id="offerDate">
          <FormLabel>Offer Date</FormLabel>
          <Input
            type="date"
            value={offerDate}
            onChange={(e) => setOfferDate(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleSubmit} isLoading={loading}>
          Create Course
        </Button>
      </VStack>
    </Container>
  );
};

export default CreateCourse;
