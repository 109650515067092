import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Text,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  HStack,
  Image,
  Center,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";

const Feedback = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [replies, setReplies] = useState({});
  const storedData = JSON.parse(sessionStorage.getItem("AdminInfo"));
  const token = storedData.token;

  const fetchFeedback = async () => {
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const data = await axios.get(
        `${mod.api_url}/api/feedback/get_feedback`,
        config
      );
      setFeedbackData(data.data.feedbacks);
    } catch (error) {
      console.error("Error fetching feedback:", error);
    }
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  return (
    <>
      <Container maxW="container.lg" p={4}>
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" mb={4}>
            Student Feedback
          </Text>
          {feedbackData?.sort().map((feed, index) => (
            <Box
              key={index}
              borderWidth="1px"
              borderRadius="lg"
              p={4}
              mb={4}
              bg="gray.50"
            >
              <Text fontWeight="bold" color="black">
                Name: {feed.name}
              </Text>
              <Text>Mobile: {feed.mobile}</Text>
              <Text mt={2}>Feedback: {feed.feedback}</Text>
              <Text mt={2} fontSize="sm" color="gray.500">
                Date: {new Date(feed.createdAt).toLocaleDateString()}
              </Text>
              <Box>
                {/* <Image src={feed.img} /> */}

                <Center>
                  {" "}
                  <img
                    src={`${mod.api_url}/api/feedback/singlePhoto/${feed._id}`}
                    alt="Uploaded"
                    className="card-img-top"
                    style={{ width: "200px", height: "auto" }}
                  />
                </Center>
              </Box>
            </Box>
          ))}
        </VStack>
      </Container>
    </>
  );
};

export default Feedback;
