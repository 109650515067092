import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Image,
  Button,
  Text,
  Input,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import * as mod from "./../../url";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Allorders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [searchEmail, setSearchEmail] = useState("");
  const toast = useToast();
  const AdminInfo = JSON.parse(sessionStorage.getItem("AdminInfo"));
  const token = AdminInfo?.token;

  const purchasedCourses = async () => {
    if (!token) {
      toast({
        title: "Unauthorized",
        description: "You must be logged in to view your purchased courses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${mod.api_url}/api/orders/admin/all-orders`,
        config
      );

      if (Array.isArray(data.orders)) {
        setOrders(data.orders);
      } else {
        console.error("Unexpected data format:", data);
        toast({
          title: "Error fetching courses.",
          description: "Unexpected data format received.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    purchasedCourses();
  }, []);

  // Filter orders based on searchEmail
  const filteredOrders = orders?.filter((order) =>
    order?.user?.email.toLowerCase().includes(searchEmail.toLowerCase())
  );

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async (_id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this course?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };
              const response = await axios.delete(
                `${mod.api_url}/api/orders/admin/deleteOrders/${_id}`,
                config
              );
              console.log("Delete response:", response);
              setOrders(orders.filter((orders) => orders._id !== _id));
              toast({
                title: "Course deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            } catch (error) {
              console.error("Error deleting course:", error.response || error);
              toast({
                title: "Error deleting course.",
                description:
                  error.response?.data?.message || "Something went wrong!",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  // Generate page numbers for pagination
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredOrders.length / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Box>
      <h1>All Orders</h1>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Box background="orange" p={2} mb={4}>
            <Text color="black">Total Orders = {filteredOrders.length} </Text>
          </Box>

          {/* Email Filter Input */}
          <Box mb={4}>
            <Input
              placeholder="Search by student email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              mb={4}
            />
          </Box>

          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Order ID</Th>
                <Th>Payment ID</Th>
                <Th>Course Image</Th>
                <Th>Student Email</Th>
                <Th>Course Name</Th>
                <Th>Course Time</Th>
                <Th>Price</Th>
                <Th>Rating</Th>
                <Th>Status</Th>
                <Th>Manage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentOrders.map((order, index) => (
                <Tr key={index}>
                  <Td>{order.orderId}</Td>
                  <Td>{order.paymentId}</Td>
                  <Td>
                    <img
                      src={order?.course?.image}
                      alt="{order.course.name}"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </Td>
                  <Td>{order?.user?.email}</Td>
                  <Td>{order?.course?.name}</Td>
                  <Td>{order?.course?.createdAt.slice(0, 10)}</Td>
                  <Td>{order?.amount}</Td>
                  <Td>{order?.course?.rating}</Td>
                  <Td>{order?.status}</Td>
                  <Td>
                    <Button
                      onClick={() => handleDelete(order?.course?._id)}
                      colorScheme="red"
                    >
                      Delete Order
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Pagination Controls */}
          <Flex mt={4} justifyContent="center" alignItems="center">
            <Button
              onClick={() => paginate(currentPage - 1)}
              isDisabled={currentPage === 1}
              mr={2}
            >
              Previous
            </Button>
            {pageNumbers.map((number) => (
              <Button
                key={number}
                onClick={() => paginate(number)}
                variant={currentPage === number ? "solid" : "outline"}
                mr={2}
              >
                {number}
              </Button>
            ))}
            <Button
              onClick={() => paginate(currentPage + 1)}
              isDisabled={currentPage === pageNumbers.length}
            >
              Next
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Allorders;
