import React, { useState, useEffect } from "react";
import * as mod from "./../../url";
import axios from "axios";
import { Box, Text, Heading } from "@chakra-ui/react";

const Contact = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/contact/get_contacts`
      );
      setData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Heading as="h3" mb={4}>
        Contact
      </Heading>
      {data &&
        data.map((d, index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            mb={4}
            boxShadow="md"
          >
            <Heading as="h4" size="md" mb={2}>
              {d.name}
            </Heading>
            <Text fontSize="sm" color="gray.600" mb={1}>
              Email: {d.email}
            </Text>
            <Text fontSize="sm" color="gray.600" mb={1}>
              Mobile: {d?.mobile}
            </Text>
            <Text fontSize="sm" color="gray.600">
              Description: {d.dec}
            </Text>
          </Box>
        ))}
    </>
  );
};

export default Contact;
