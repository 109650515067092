import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useToast,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import * as mod from "./../../url";
import Navbar from "../Navbar/Navbar";
import { useParams } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";

const UpdateCourse = () => {
  const [name, setName] = useState("");
  const [courseId, setCourseID] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [offers, setOffers] = useState("");
  const [currency, setCurrency] = useState("INR");
  const [pdf, setPdf] = useState(null);
  const [videourl, setVideourl] = useState("");
  const [image, setImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [offerDate, setOfferDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const toast = useToast();
  const { slug } = useParams();

  // Fetch course details on component mount
  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const storedData = JSON.parse(sessionStorage.getItem("AdminInfo"));
        const token = storedData.token;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const { data } = await axios.get(
          `${mod.api_url}/api/course/get_course/${slug}`,
          config
        );
        const course = data.Course;

        setName(course.name || "");
        setCourseID(course._id || "");
        setDescription(course.description || "");
        setPrice(course.price || "");
        setOffers(course.offers || "");
        setCurrency(course.currency || "INR");
        setVideourl(course.videourl || "");
        setRating(course.rating || 0);
        setOfferDate(course.offerDate || "");
        setPdf(course.pdf || null);
        setImage(course.image || null);
      } catch (error) {
        console.error("Error fetching course details:", error);
        toast({
          title: "Error fetching course details.",
          description: error.response?.data?.message || "Something went wrong!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsFetching(false);
      }
    };

    fetchCourseDetails();
  }, [slug, toast]);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const storedData = JSON.parse(sessionStorage.getItem("AdminInfo"));
    const token = storedData.token;

    if (!name || !description || !price) {
      toast({
        title: "Validation Error",
        description: "Name, description, and price are required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("offers", offers);
    formData.append("currency", currency);
    formData.append("videourl", videourl);
    if (pdf) formData.append("pdf", pdf);
    if (image) formData.append("image", image);
    formData.append("rating", rating);
    formData.append("offerDate", offerDate);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.put(
        `${mod.api_url}/api/course/course_update/${courseId}`,
        formData,
        config
      );
      console.log(data);
      toast({
        title: "Course updated successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset form fields after successful update
      setName("");
      setDescription("");
      setPrice("");
      setOffers("");
      setVideourl("");
      setCurrency("INR");
      setPdf(null);
      setImage(null);
      setRating(0);
      setOfferDate("");
    } catch (error) {
      toast({
        title: "Error occurred.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <Box padding="10px">
        <h1>Update Your Course</h1>
        {isFetching ? (
          <Spinner size="xl" />
        ) : (
          <form onSubmit={handleSubmit}>
            <FormControl mb={4} isRequired>
              <FormLabel>Course Name</FormLabel>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Offers</FormLabel>
              <Input
                type="text"
                value={offers}
                onChange={(e) => setOffers(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Currency</FormLabel>
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="USD">USD</option>
                <option value="INR">INR</option>
                {/* Add more currencies as needed */}
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Video URL</FormLabel>
              <Input
                type="text"
                value={videourl}
                onChange={(e) => setVideourl(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>PDF File</FormLabel>
              {/* Display existing PDF file link if available */}
              {pdf && (
                <Box textAlign="left" padding={2} mb={2}>
                  {pdf}
                </Box>
              )}
              <Input
                type="file"
                onChange={(e) => setPdf(e.target.files[0])}
                style={{ padding: "3px" }}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Image File</FormLabel>
              {/* Display existing image file preview if available */}
              {image && (
                <Box mb={2}>
                  <img
                    src={image}
                    alt="Current"
                    style={{ width: "100px", height: "100px", padding: "5px" }}
                  />
                </Box>
              )}
              <Input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                style={{ padding: "3px" }}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Rating</FormLabel>
              <Input
                type="number"
                value={rating}
                onChange={(e) => setRating(Number(e.target.value))}
                min="0"
                max="5"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Offer Date</FormLabel>
              <Box textAlign="left" padding={2} display="flex">
                <FaCalendarAlt style={{ marginRight: "10px" }} />
                <Text color="red"> {offerDate.slice(0, 10)}</Text>
              </Box>
              <Input
                type="date"
                value={offerDate}
                onChange={(e) => setOfferDate(e.target.value)}
              />
            </FormControl>

            <Button type="submit" colorScheme="teal" isLoading={loading}>
              {loading ? "Updating..." : "Update Course"}
            </Button>
          </form>
        )}
      </Box>
    </>
  );
};

export default UpdateCourse;
