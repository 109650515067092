import {
  Box,
  Text,
  Heading,
  Divider,
  Flex,
  Avatar,
  Stack,
  Badge,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
} from "@chakra-ui/react";
import React from "react";

const AdminInfo = JSON.parse(sessionStorage.getItem("AdminInfo"));

const Profile = () => {
  if (!AdminInfo) {
    return <Text color="red.500">No user data found.</Text>;
  }

  return (
    <Box maxWidth="800px" mx="10%" borderRadius="10px" boxShadow="lg ">
      <Heading as="h1" mb={4} color="blue.400" bg="floralwhite" borderRadius="full">
        Admin Profile
      </Heading>

      <Table variant="simple">
        <Tbody>
          <Tr>
            <Td fontWeight="bold" borderRadius="10px" background="blue.300" color="white">
              Profile
            </Td>
            <Td fontWeight="bold" borderRadius="10px" background="blue.200" color="white">
              <Center>
                {" "}
                <Avatar src="https://bit.ly/broken-link" />
              </Center>
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold" borderRadius="10px" background="blue.300" color="white">
              Email
            </Td>
            <Td fontWeight="bold" borderRadius="10px" background="blue.200" color="white">
              {AdminInfo.admin.email}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold" borderRadius="10px" background="blue.300" color="white">
              Admin Name
            </Td>
            <Td fontWeight="bold" borderRadius="10px" background="blue.200" color="white">
              {AdminInfo.admin.name}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold" borderRadius="10px" background="blue.300" color="white">
              Mobile
            </Td>
            <Td fontWeight="bold" borderRadius="10px" background="blue.200" color="white">
              {AdminInfo.admin.mobile}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold" borderRadius="10px" background="blue.300" color="white">
              Role
            </Td>
            <Td fontWeight="bold" borderRadius="10px" background="blue.200" color="white">
              {AdminInfo.admin.role}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default Profile;
