import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Navbar from "../Navbar/Navbar";
import Profile from "./Profile";
import CreateCourse from "./CreateCourse";
import Feedback from "./Feedback";
import ManageCourse from "./ManageCourse";
import AllUsers from "./AllUsers";
import Liveclass from "./Liveclass";
import Contact from "./Contact";
import Allorders from "./Allorders";

const Home = () => {
  const [selectedSection, setSelectedSection] = useState("dashboard");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "createcouse":
        return <CreateCourse />;
      case "feedback":
        return <Feedback />;
      case "manageallcourse":
        return <ManageCourse />;

      case "allusers":
        return <AllUsers />;
      case "liveclass":
        return <Liveclass />;
      case "orders":
        return <Allorders />;
      case "contact":
        return <Contact />;

      default:
        return <Profile />;
    }
  };

  return (
    <>
      {/* Fixed Header */}
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>

      <Flex>
        {/* Mobile Hamburger Icon */}
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          display={{ base: "block", md: "none" }}
          onClick={onOpen}
          position="fixed"
          top="100px"
          left="20px"
          zIndex="overlay"
        />

        {/* Sidebar for Desktop */}
        <Box
          width="20%"
          bg="floralWhite"
          p={2}
          boxShadow="lg"
          display={{ base: "none", md: "block" }}
          position="fixed"
          top="90px" // Adjust this value according to the Navbar height
          left="0"
          height="70vh"// Adjust according to Navbar height
          overflowY="auto"

        >
          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => setSelectedSection("profile")}
          >
            Admin Profile
          </Button>
          <Button
            colorScheme="blue"
            width="100%"
            mb={4}
            onClick={() => setSelectedSection("createcouse")}
          >
            Create Course
          </Button>
          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => setSelectedSection("manageallcourse")}
          >
            Manage Courses
          </Button>

          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => setSelectedSection("feedback")}
          >
            Feedback{" "}
          </Button>
          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => setSelectedSection("allusers")}
          >
            All Learners{" "}
          </Button>
          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => setSelectedSection("liveclass")}
          >
            Create Live Class{" "}
          </Button>
          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => setSelectedSection("orders")}
          >
            All Orders
          </Button>
          <Button
            width="100%"
            colorScheme="blue"
            mb={4}
            onClick={() => {
              setSelectedSection("contact");
              onClose();
            }}
          >
            Contact Data
          </Button>

          {/* Add more buttons for different sections */}
        </Box>

        {/* Main Content */}
        <Box
          flex="1"
          ml={{ base: 0, md: "20%" }}
          pt="100px" // Adjust according to Navbar height
          p={6}
          marginTop="100px"
          overflowY="auto"
          maxHeight="calc(100% - 100px)" // Adjust according to Navbar height
        >
          {renderContent()}
        </Box>
      </Flex>

      {/* Drawer for Mobile */}
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("profile");
                onClose();
              }}
            >
              Admin Profile
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("createcouse");
                onClose();
              }}
            >
              Create Course
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("manageallcourse");
                onClose();
              }}
            >
              Manage Course
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("feedback");
                onClose();
              }}
            >
              Feedback
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("allusers");
                onClose();
              }}
            >
              All Learners
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("liveclass");
                onClose();
              }}
            >
              Create Live Class
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("orders");
                onClose();
              }}
            >
              All Orders
            </Button>
            <Button
              width="100%"
              colorScheme="blue"
              mb={4}
              onClick={() => {
                setSelectedSection("contact");
                onClose();
              }}
            >
              Contact Data
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Home;
