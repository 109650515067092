import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
  useToast,
  Divider,
  CardBody,
  CardFooter,
  Link,
  CardHeader,
  Card,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url"; // Adjust the path according to your project structure
import { confirmAlert } from "react-confirm-alert";

const Liveclass = () => {
  const [coursename, setCoursNname] = useState("");
  const [classname, setClassname] = useState("");
  const [liveClassUrl, setLiveClassUrl] = useState("");
  const toast = useToast();
  const [data, setData] = useState([]);
  const storedData = JSON.parse(sessionStorage.getItem("AdminInfo"));
  const token = storedData.token;

  const handleSubmit = async () => {
    if (!classname || !liveClassUrl || coursename.length === 0) {
      toast({
        title: "All fields are required",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post(`${mod.api_url}/api/liveUrl/live_classes`, {
        coursename,
        classname,
        liveClassUrl,
      });
      toast({
        title: "Live class link sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setClassname("");
      setCoursNname("");
      setLiveClassUrl([]);
    } catch (error) {
      toast({
        title: "Error sending live class link.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const educationOptions = [
    "All types",
    "Govt",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "ssc",
    "police",
    "bank",
  ];

  const getliveclass = async () => {
    try {
      const res = await axios.get(`${mod.api_url}/api/liveUrl/live_classes`);
      setData(res.data);
      console.log();
    } catch (error) {}
  };

  useEffect(() => {
    getliveclass();
  }, []);

  const handleDelete = async (_id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this course?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };
              const response = await axios.delete(
                `${mod.api_url}/api/liveUrl/live_classes/${_id}`,
                config
              );
              console.log("Delete response:", response);
              setData(data.filter((course) => course._id !== _id));
              toast({
                title: "Course deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            } catch (error) {
              console.error("Error deleting course:", error.response || error);
              toast({
                title: "Error deleting course.",
                description:
                  error.response?.data?.message || "Something went wrong!",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            // Do nothing if 'No' is clicked
          },
        },
      ],
    });
  };

  return (
    <Container maxW="container.md" p={4}>
      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" mb={4}>
            Live Course Link
          </Text>
          <FormControl isRequired>
            <FormLabel>Course Name</FormLabel>
            <Input
              placeholder="Enter Course name"
              value={coursename}
              onChange={(e) => setCoursNname(e.target.value)}
            />
          </FormControl>
          <FormControl id="education" isRequired mr="4">
            <FormLabel>Course Group</FormLabel>
            <Select
              placeholder="Select Education"
              onChange={(e) => setClassname(e.target.value)}
              value={classname}
            >
              {educationOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Course URL</FormLabel>
            <Input
              placeholder="Enter class URL"
              value={liveClassUrl}
              onChange={(e) => setLiveClassUrl(e.target.value)}
            />
          </FormControl>

          <Button colorScheme="blue" onClick={handleSubmit}>
            Send Link
          </Button>
        </VStack>
      </Box>
      <Divider mb={4} />

      <Box>
        <Text className="xx-large" fontWeight="600" marginTop="30px">
          Manage Live Class
        </Text>
        <VStack spacing={4} align="stretch">
          {data.map((link, index) => (
            <Card key={index} p={4} shadow="md" borderWidth="1px">
              <CardHeader>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  background="whitesmoke"
                  borderRadius="30px"
                >
                  Date:{new Date(link.createdAt).toLocaleDateString()}
                </Text>
              </CardHeader>
              <CardBody border="1px solid #c9c9c9" borderRadius="30px">
                <Text mb={2}>
                  <strong>Class Name:</strong> {link.coursename}
                </Text>
                <Text mb={2}>
                  <strong>Group:</strong> {link.classname}
                </Text>
              </CardBody>
              <CardFooter>
                <Button
                  colorScheme="red"
                  width="100%"
                  onClick={() => handleDelete(link._id)}
                >
                  Delete
                </Button>
              </CardFooter>
            </Card>
          ))}
        </VStack>
      </Box>
    </Container>
  );
};

export default Liveclass;
